<template>
  <div
    class="col-12"
    :class="{ 'threeNinetyWidth': mobileView }"
  >
    <div class="container-right-half">
      <div style="margin-left:0px; margin-right:0px;width:100%;padding:0px;margin-top: 20px;">
        <div class="text-left primary-colour">
          <validation-observer v-slot="{ invalid:invalid }">
            <form>
              <div class="h5" style="margin-top: 0px;padding-bottom: 10px;text-align: center;font-weight: bold;">
                <span v-show="nmError">{{ $parent.$parent.error }}</span>
                <span v-show="!nmError">Login</span>
              </div>
              <div class="md-form">
                <div style="margin-top:40px;">
                  <validation-provider
                    mode="lazy"
                    name="Email"
                    rules="required"
                  >
                    <i class="fas fa-user prefix sm" style="left:0;margin-top:10px;font-size:1rem;color:gray;" />
                    <mdb-input
                      v-model="userName"
                      type="email"
                      label="Email"
                      style="margin-left:33px;"
                      size="sm"
                    />
                  </validation-provider>
                </div>
                <div style="margin-top:35px;">
                  <validation-provider
                    mode="lazy"
                    name="Password"
                    rules="required"
                  >
                    <i class="fas fa-lock prefix sm" style="left:0;margin-top:85px;font-size:1rem;color:gray;" />
                    <mdb-input
                      id="input-password"
                      v-model="passWord"
                      :type="visibility"
                      label="Password"
                      style="margin-left:33px;"
                      size="sm"
                    />
                    <a
                      v-if="visibility == 'password'"
                      style="position:absolute;right:0;top:85px;"
                      @click="showPassword()"
                    ><i class="fas fa-eye grey-text" /></a>
                    <a
                      v-if="visibility == 'text'"
                      style="position:absolute;right:0;top:85px;"
                      @click="hidePassword()"
                    ><i class="fas fa-eye-slash grey-text" /></a>
                  </validation-provider>
                </div>
              </div>
              <div style="width:100%;float:left;font-size:small;text-align: left;margin-top: 5px;margin-left:30px;margin-bottom: 15px;text-decoration: underline;cursor: pointer;z-index: 1000;" @click="toggleRememberMe()">
                <mdb-input
                  v-model="rememberMe"
                  type="checkbox"
                  style="margin-left:-20px;width:40px;"
                  size="sm"
                />
                <div style="float:left;font-size:small;text-align: left;margin-top: -28px;margin-left:30px;cursor: pointer;z-index: 1000;margin-bottom: 0px;">
                  Remember me? 
                </div>
              </div>
              <div style="width:100%;float:left;font-size:small;text-align: left;margin-left:30px;margin-bottom: 0px;text-decoration: underline;cursor: pointer;z-index: 1000;" @click="toggleAgree()">
                <mdb-input
                  v-model="agree"
                  type="checkbox"
                  style="margin-left:-20px;width:40px;"
                  size="sm"
                />
                <div style="float:left;font-size:small;text-align: left;margin-top: -28px;margin-left:30px;cursor: pointer;z-index: 1000;margin-bottom: 0px;">
                  I have read the  
                  <a
                    class="primary-colour"
                    style="text-decoration: underline;"
                    @click="privacyLink()"
                  >privacy</a> policy.
                </div>
              </div>
              <!-- login button-->
              <div style="position: fixed; bottom: 15px; float:right;right:30px;">
                <div class="grey-text text-right" style="margin-top: -20px !important;">
                  <span style="font-size:x-small;">Forgotten your password?<a class="ml-1 primary-colour" @click="forgottenPassword">Click here!</a></span><br>
                </div>
                <div v-if="counter < 3 && lockout === null" style="margin-top:10px;">
                  <mdb-btn
                    v-show="!buttonClick"
                    :disabled="invalid || agree === false"
                    class="float-right"
                    style="width:110px;bottom: 0px !important;right: -5px !important;"
                    size="sm"
                    :class="{ 'mobButtonHeight': mobileView }"
                    @click="doLogin"
                  >
                    LOGIN
                  </mdb-btn>
                  <mdb-btn
                    v-show="buttonClick"
                    class="float-right"
                    style="width:110px;bottom: 0px !important;right: -5px !important;"
                    size="sm"
                    :class="{ 'mobButtonHeight': mobileView }"
                  >
                    <mdb-icon icon="fa fa-pulse fa-spinner" />
                  </mdb-btn>
                  <br><br>
                </div>
                <div v-else style="margin-top:20px;">
                  <mdb-btn
                    disabled
                    class="float-right"
                    style="width:110px;bottom: 0px !important;right: -5px !important;"
                    size="sm"
                    :class="{ 'mobButtonHeight': mobileView }"
                  >
                    LOGIN
                  </mdb-btn>
                  <br><br>
                </div>
              </div>
            </form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mdbBtn } from 'mdbvue'
import signUpService from '@/api/services/signup.service'
import { GUID } from '@/common/guid'
import { setDefaultLanding } from '@/utils/defaultLanding.js'
import { storeToRefs } from 'pinia'
import { useUserData , useStatus } from '@/pinia'
import { useMobile } from '@/composables/useMobile'

export default {
  name: 'SignIn',
  components: {
    mdbBtn
  },
  setup () {
    const userStore = useUserData()
    const { userData, authData, leftColumnActive, lockout, landingPage } = storeToRefs(userStore)
    const { closeGenericConfirmModal } = useStatus()
    const { mobileView } = useMobile()

    return {
      userStore,
      userData,
      authData,
      leftColumnActive,
      lockout,
      closeGenericConfirmModal,
      landingPage,
      mobileView
    }
  },
  data () {
    return {
      windowHeight: window.innerHeight,
      userName: '',
      passWord: '',
      error: '',
      errorTitle: 'Error',
      buttonClick: false,
      visibility: 'password',
      errorModal: false,
      login: true,
      windowWidth: window.innerWidth,
      counter: 0,
      nmError: false,
      agree: false,
      rememberMe: false
    }
  },
  created () {
    var now = new Date()
    var minutes = now.getMinutes()
    if (this.lockout != null) {
      if (this.lockout < minutes) {
        this.lockout = null
        this.counter = 0
      }
    }
    this.windowHeight = window.innerHeight
    this.closeGenericConfirmModal()
  },
  methods: {   
    privacyLink () {
      window.open('https://www.shineinterview.com/privacy-policy/', '_blank')     
    },
    toggleAgree () {
      if (this.agree === false) { this.agree = true } else { this.agree = false }
    },
    toggleRememberMe () {
      if (this.rememberMe === false) { this.rememberMe = true } else { this.rememberMe = false }
    },
    doLogin: async function () {
      this.nmError = false
      this.buttonClick = true
      signUpService.login(this.userName, this.passWord, this.rememberMe).then(async (res) => {
        this.authData = res.data
        this.leftColumnActive = false
        await this.getUserDetails(res.data.access_token)
        this.agree === false
      }).catch(() => {
        try {
          this.agree = false
          this.rememberMe = false
          this.counter++
          this.$parent.$parent.errorModal = true
          this.$parent.$parent.error = "Sorry your username or password is incorrect. Please contact us if you believe this is an error."
          if (this.counter > 2) {
            this.$parent.$parent.errorModal = false
            this.nmError = false
            if (this.mobileView === false) { this.$parent.$parent.errorModal = true } else { this.nmError = true }
            this.$parent.$parent.errorTitle = 'Error'
            this.$parent.$parent.error = 'Sorry, too many login attempts have been made, please try again in 5 minutes (contact us if you believe this is an error).'
            this.counter++
            var twoMinutesLater = new Date()

            this.lockout = twoMinutesLater.getMinutes() + 5
          }
          this.buttonClick = false
        } catch (err) {
          if (this.mobileView === false) { this.$parent.$parent.errorModal = true } else { this.nmError = true }
          this.$parent.$parent.error = 'Sorry your username or password is incorrect. Please contact us if you believe this is an error.'
          this.buttonClick = false
          this.agree = false
          this.rememberMe = false
          this.counter++
        }
      })
    },
    async getUserDetails (token) {
      signUpService.getUserDetails(token).then(async (res) => {
        this.userData = res.data
        this.leftColumnActive = false
        await setDefaultLanding(this.userData.dlp)
        this.$router.push(this.landingPage)
      }).catch((error) => {
        this.buttonClick = false
        this.errorAlert(error, 'Sorry your username or password is incorrect. Please contact us if you believe this is an error.')
      })
    },
    register () {
      this.$router.push('/signup').catch(() => { })
    },
    resetLogin () {
      signUpService.resetAccount(this.userName, GUID).then(() => {
        this.$parent.$parent.errorModal = true
        this.$parent.$parent.errorTitle = 'Account Reset'
        this.$parent.$parent.error = 'Your account has been reset. An email has been sent to you in regards to this.'
      })
    },
    forgottenPassword () {
      this.$emit('setPassword')
    },
    showPassword () {
      this.visibility = 'text'
    },
    hidePassword () {
      this.visibility = 'password'
    },
    errorAlert (error, msg) {
      try {
        if (error.response.data != null) {
          if (error.response.data.Message != null) {
            this.$parent.$parent.errorModal = true
            this.$parent.$parent.error = 'Sorry your username or password is incorrect. Please contact us if you believe this is an error.'
          } else if (error.response.data.error_description != null) {
            this.$parent.$parent.errorModal = true
            this.$parent.$parent.error = 'Sorry your username or password is incorrect. Please contact us if you believe this is an error.'
          } else {
            this.$parent.$parent.errorModal = true
            this.$parent.$parent.error = error.response.data
          }
        } else {
          this.$parent.$parent.errorModal = true
          this.$parent.$parent.error = 'Sorry your username or password is incorrect. Please contact us if you believe this is an error.'
        }
      } catch {
        this.$parent.$parent.errorModal = true
        this.$parent.$parent.error = 'Sorry your username or password is incorrect. Please contact us if you believe this is an error.'
      }
    }
  }
}
</script>

<style>
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    box-shadow: 0 0 0px 1000px #ffffff inset !important;
  }
  .close {
    color:#c0c0c0 !important;
  }
</style>
