<template>
  <div>
    <div
      v-if="useableTemplateId != 0 && userCanChangeThisScore"
      style="position:sticky;top:8px;background:#fff;z-index:4000;height:65px;transform:translateY(-10px);"
      class="d-flex align-items-center ml-1"
    >
      <mdb-btn
        v-if="!saving && !saved && !loading"
        class="mb-0 text-nowrap"
        size="sm"
        style="width:100px;background:#fff;"
        @click="saveScore()"
      >
        SAVE
      </mdb-btn>
      <mdb-btn
        v-else-if="!saving && saved"
        size="sm"
        class="mb-0"
        style="width:100px;"
      >
        <mdb-icon icon="fa fa-check" />
      </mdb-btn>
      <mdb-btn
        v-else
        size="sm"
        class="mb-0"
        style="width:100px;"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
      <span
        v-if="unsavedChanges"
        class="mt-1 font-weight-bold ml-3 mr-2"
        style="font-size:0.8rem"
      >YOU HAVE UNSAVED CHANGES!</span>
      <span
        v-if="userData.uid !== scoringItemUserId && !unsavedChanges"
        class="mt-1 font-weight-bold ml-3 mr-2"
        style="font-size:0.8rem"
      >AS AN ADMIN YOU MAY OVERWRITE THIS SCORE BUT THE SCORE WILL NOT BE ASSIGNED TO YOU</span>
      <span v-if="userData.iss || userData.rle === 'Admin' && scoringItemUserId != '' || userData.rle === 'Account Group Administrator' && scoringItemUserId != '' || userData.rle === 'AccountGroupAdministrator' && scoringItemUserId != ''" class="ml-auto mr-2">
        <mdb-btn
          v-if="!deletingScore"
          class="mb-0 text-nowrap btn-outline"
          size="sm"
          style="width:100px;background:#fff;"
          @click="deleteScore()"
        >DELETE</mdb-btn>
        <mdb-btn
          v-else
          size="sm"
          class="mb-0 btn-outline"
          style="width:100px;"
        ><mdb-icon icon="fa fa-check" /></mdb-btn>
      </span>
    </div>
    <TemplateDetailSummary
      v-if="roleTemplateId === -1"
      id="main"
      :text="selectedQuestion.QuestionText"
      :number="selectedQuestion.Ordinal"
      :score="totalQuestionScore"
      :max-score="totalPossibleScore"
      :loading="loading"
      :template-id="useableTemplateId"
    />
    <TemplateDetailSummary
      v-else
      id="main"
      :score="totalQuestionScore"
      :max-score="totalPossibleScore"
      :loading="loading"
      :is-role-summary="true"
      :template-id="useableTemplateId"
    />

    <div v-if="useableTemplateId > 0">
      <div v-if="!loading">
        <QuestionTemplate
          :template="template"
          :element-scores="elementScores"
          :style="{'pointer-events' : userCanChangeThisScore ? 'auto' : 'none'}"
          @setSliderScore="setSliderScore($event)"
          @setSliderComment="setSliderComment($event)"
        />
      </div>
    </div>
    <div
      v-else
      class="font-weight-bold m-2 mt-3"
      style="font-size: 0.8rem"
    >
      POINTS ARE NOT AVAILABLE TO GIVE FOR THIS QUESTION
    </div>
  </div>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import scoringService from '@/api/services/scoring.service.js'
import scoringV2Service from '@/api/services/scoringV2.service.js'

import TemplateDetailSummary from '@/components/misc/ReviewModalV2/reviewModalComponents/scoring/question/TemplateDetailSummary'
import QuestionTemplate from '@/components/misc/ReviewModalV2/reviewModalComponents/scoring/question/QuestionTemplate'

/// roleTemplateId === -1 when template is per question
export default {
  name: 'ScoringDetail',

  components: {
    TemplateDetailSummary,
    QuestionTemplate
  },

  props: {
    selectedQuestion: {
      type: Object,
      default: () => {}
    },
    roleTemplateId: {
      type: Number,
      default: 0
    },
    templateId: {
      type: Number,
      default: 0
    },
    candidateApplication: {
      type: Object,
      default: () => {}
    },
    scoringItemUserId: {
      type: String,
      default: ''
    }
  },

  setup () {
    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)

    const { showGenericErrorModal, showGenericConfirmModal, closeGenericConfirmModal } = useStatus()

    return {
      authData,
      userData,
      showGenericErrorModal,
      showGenericConfirmModal,
      closeGenericConfirmModal
    }
  },

  data () {
    return {
      defaultTemplate: {
        TemplateName: '',
        TemplateId: 0,
        IsDefault: false,
        ElementArray: [{ Elements: [] }]
      },
      template: {
        TemplateName: '',
        TemplateId: 0,
        IsDefault: false,
        ElementArray: [{ Elements: [] }]
      },
      loading: false,
      elementScores: [],
      saving: false,
      saved: false,
      unsavedChanges: false,
      deletingScore: false
    }
  },

  computed: {
    // roleTemplateId is the template currently set against the role, templateId is the Id of the score overview currently selected
    useableTemplateId () {
      if (this.roleTemplateId === -1) {
        return this.selectedQuestion.GoScoringTemplateId
      } else {
        return this.templateId
      }
    },
    userCanChangeThisScore () {
      return this.scoringItemUserId === this.userData.uid || this.userData.rle === 'Admin' || this.userData.rle === 'AccountGroupAdministrator' || this.userData.rle === 'Account Group Administrator' || this.userData.iss
    },
    allIndividualElements () {
      const elements = []
      if (this.template.ElementArray.length > 0) {
        this.template.ElementArray.map(comp => {
          if (comp.CompetencyName === '') {
            elements.push(comp.Elements[0])
          } else {
            comp.Elements.map(e => elements.push(e))
          }
        })
      }
      return elements
    },
    totalQuestionScore () {
      let totalOfScores = 0
      this.elementScores.forEach(score => {
        totalOfScores += score.Score
      })
      return totalOfScores
    },
    totalPossibleScore () {
      let totalPossible = 0
      this.allIndividualElements.forEach(s => {
        totalPossible += s.MaximumPoints
      })
      return totalPossible
    }
  },

  watch: {
    'selectedQuestion.QuestionId': {
      handler: async function () {
        if (this.roleTemplateId === -1) {
          this.elementScores = []
          await this.fetchScoringTemplate()
          await this.getQuestionScores()
        }
      },
      deep: true
    },
    roleTemplateId: {
      handler: async function () {
        if (this.roleTemplateId > 0) {
          this.elementScores = []
          await this.fetchScoringTemplate()
          await this.getQuestionScores()
        }
      },
      deep: true
    },
    // creates the default scores when none have been saved
    allIndividualElements () {
      if (this.allIndividualElements.length > 0) {
        const elementScores = []
        this.allIndividualElements.map(e => {
          elementScores.push({ Score: e.LowestScaleValue, GoScoringElementId: e.GoScoringElementId, NumberOfSteps: e.NumberOfSteps, GoScoringScoreId: 0, Comment: '' })
        })
        if (this.elementScores.length === 0) {
          this.elementScores = [...elementScores]
        }
      }
    }
  },

  async created () {
    // make api call here, load data for props
    const elem = document.getElementById('main')
    elem.scrollIntoView({ block: 'start' })
    await this.fetchScoringTemplate()
    await this.getQuestionScores()
  },

  methods: {
    deleteScore () {
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: 'DELETE',
        confirmMessage: 'Are you sure you want to delete this score?',
        confirmButtonMessage: 'CONFIRM',
        confirmButtonFunction: () => this.confirmDeleteScore()
      })
    },
    async confirmDeleteScore () {
      this.deletingScore = true
      try {
        const JSONData = {
          CandidateId: this.candidateApplication.CandidateId,
          QuestionId: this.roleTemplateId === -1 ? this.selectedQuestion.QuestionId : 0,
          ScoringItemUserId: this.scoringItemUserId,
          InvitationCode: this.candidateApplication.InvitationCode,
          TemplateId: this.useableTemplateId
        }
        const res = await scoringV2Service.deleteScore(JSONData)
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      this.closeGenericConfirmModal()
      this.$emit('showDetail', false)
      setTimeout(() => {
        this.deletingScore = false
      }, 500)
    },
    async fetchScoringTemplate () {
      if (this.useableTemplateId > 0) {
        this.loading = true
        const allowDeleted = true
        try {
          const res = await scoringService.getTemplate(this.useableTemplateId, allowDeleted)
          this.template = { ...res.data, ElementArray: [...res.data.ElementArray] }
        } catch (err) {
          this.showGenericErrorModal({ showing: true })
        }
        setTimeout(() => {
          this.loading = false
        }, 500)
      } else {
        this.template = { ...this.defaultTemplate }
      }
    },
    async saveScore () {
      this.saving = true
      const JSONData = {
        Scores: JSON.stringify(this.elementScores),
        TemplateId: this.useableTemplateId,
        CandidateId: this.candidateApplication.CandidateId,
        QuestionId: this.roleTemplateId === -1 ? this.selectedQuestion.QuestionId : 0,
        RoleId: this.candidateApplication.JobId,
        RoleWorkflowStageId: this.candidateApplication.RoleWorkflowStageId,
        InvitationCode: this.candidateApplication.InvitationCode
      }
      try {
        await scoringV2Service.saveScore(JSONData)
        this.getQuestionScores(false)
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      this.saved = true
      setTimeout(() => {
        this.saving = false
        this.unsavedChanges = false
      }, 500)
      setTimeout(() => {
        this.saved = false
      }, 2500)
    },
    async getQuestionScores (setLoading = true) {
      if (this.useableTemplateId > 0) {
        this.loading = setLoading
        try {
          const questionId = this.roleTemplateId === -1 ? this.selectedQuestion.QuestionId : 0
          const JSONData = {
            InvitationCode: this.candidateApplication.InvitationCode,
            UserWhoScoredId: this.scoringItemUserId,
            TemplateId: this.useableTemplateId,
            CandidateId: this.candidateApplication.CandidateId,
            QuestionId: questionId
          } 
          const res = await scoringV2Service.getQuestionScores(JSONData)
          this.$nextTick(() => {
            if (res.data != null && res.data.length > 0) {
              this.elementScores = [...res.data]
            }
          })
        } catch (err) {
          this.showGenericErrorModal({ showing: true })
        }
        setTimeout(() => {
          this.loading = false
        }, 500)
      }
    },
    setSliderScore ({ score, elementId }) {
      this.unsavedChanges = true
      // if text based need to find the integer value of the score
      const el = this.allIndividualElements.filter(e => e.GoScoringElementId === elementId)[0]
      if (el.IsTextBased && !el.NumericTextScores) {
        score = (el.TextArray.findIndex(s => s === score) * el.StepIncrement) + el.LowestScaleValue
      }
      this.elementScores = [...this.elementScores.map(s => s.GoScoringElementId === elementId ? { ...s, Score: Number(score) } : s)]
    },
    setSliderComment ({ elementId, comment }) {
      this.unsavedChanges = true
      // if text based need to find the integer value of the score
      this.elementScores = this.elementScores.map(s => s.GoScoringElementId === elementId ? { ...s, Comment: comment } : s)
    }
  }
}
</script>
