<template>
  <div>
    <div
      v-if="!loadingStage"
      class="d-flex justify-content-center border-center mt-3"
      style="justify-content: space-between !important;"
    >
      <CircleNavigationItem
        v-for="(nav, i) in oneWaySetupNavigationItems"
        :key="i"
        :query-to-match="$route.query.page"
        :nav-item-count="oneWaySetupNavigationItems.length"
        :nav-item="nav"
        @clicked="handleButtonNavigation(nav.Step)"
      />
    </div>

    <SlideFade v-if="!loadingStage">
      <SettingUp v-if="queryMatches('setting_up')" />

      <Questions
        v-else-if="queryMatches('questions')"
        @refreshWorkflowStage="refreshWorkflowStage()"
      />

      <ReadingAndRetries v-else-if="queryMatches('reading_and_retries')" />

      <NextSteps v-else-if="queryMatches('next_steps')" />
    </SlideFade>
  </div>
</template>

<script>
import NextSteps from './nextSteps.vue'
import Questions from './Questions/questions.vue'
import ReadingAndRetries from './readingAndRetries.vue'
import SettingUp from './settingUp.vue'
import roleV2Service from '@/api/services/roleV2.service'
import SlideFade from '@/components/UI/TransitionComponents/slideFade.vue'
import CircleNavigationItem from '@/components/misc/circleNavigationItems.vue'

import { ref } from 'vue'
import { useStatus, useWorkflow } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  name: 'OneWayStageSetupContainer',
  components: { NextSteps, Questions, ReadingAndRetries, SettingUp, SlideFade, CircleNavigationItem },
  setup () {

    const workflowStore = useWorkflow()
    const { selectedWorkflowStageId, selectedWorkflowStage, oneWayStageHasBeenStarted, role } = storeToRefs(workflowStore)

    const { showGenericErrorModal } = useStatus()

    const loadingStage = ref(true)

    return {
      showGenericErrorModal,
      selectedWorkflowStage,
      selectedWorkflowStageId,
      loadingStage,
      oneWayStageHasBeenStarted,
      role
    }
  },
  computed: {
    currentStep () {
      try {
        return this.selectedWorkflowStage.Step
      } catch { return 0 }
    },
    oneWaySetupNavigationItems () {
      return [
        { Step: 1, Query: 'setting_up', Text: 'Setting Up', ActualStep: this.currentStep },
        { Step: 2, Query: 'questions', Text: 'Questions', ActualStep: this.currentStep },
        { Step: 3, Query: 'reading_and_retries', Text: 'Preparation Time And Retries', ActualStep: this.currentStep },
        { Step: 4, Query: 'next_steps', Text: 'Next Steps', ActualStep: this.currentStep }
      ]
    }
  },
  async mounted () {
    await this.getRole()
    this.getRoleWorkflowStage()
    this.checkStageHasBeenStarted()
  },
  methods: {
    async refreshWorkflowStage () {
      await this.getRoleWorkflowStage()
      this.$nextTick(() => {
        this.$router.push(`/roles/add_edit/one_way_stage_setup/${this.$route.params.jobId}/${this.$route.params.roleWorkflowStageId}?page=reading_and_retries`).catch(() => { })
      })
    },
    async getRoleWorkflowStage () {
      try {
        const res = await roleV2Service.getRoleWorkflowStage(this.$route.params.roleWorkflowStageId)
        this.selectedWorkflowStage = res.data
        this.$nextTick(() => {
          this.loadingStage = false
        })
      } catch (err) {
        this.showGenericErrorModal()
      }
    },
    async getRole () {
      if (this.role.JobId) return
      try {
        const res = await roleV2Service.getRole(this.$route.params.jobId)
        this.role = res.data
      } catch (err) {
        this.showGenericErrorModal()
      }
    },
    async checkStageHasBeenStarted () {
      try {
        const res = await roleV2Service.checkOneWayStageHasBeenStarted(this.$route.params.roleWorkflowStageId)
        this.oneWayStageHasBeenStarted = res.data
      } catch (err) {
        this.showGenericErrorModal()
      }
    },
    queryMatches (query) {
      return this.$route.query.page === query
    },
    goToWorkFlowPage (query) {
      this.$router.push({ path: `/roles/add_edit/one_way_stage_setup/${this.$route.params.jobId}/${this.$route.params.roleWorkflowStageId}`, query: { page: query } })
    },
    handleButtonNavigation (step) {
      if (step === 1) {
        this.goToWorkFlowPage('setting_up')
      } else if (step === 2) {
        if (this.selectedWorkflowStage.Step >= 1) {
          this.goToWorkFlowPage('questions')
        }
      } else if (step === 3) {
        if (this.selectedWorkflowStage.Step >= 2) {
          this.goToWorkFlowPage('reading_and_retries')
        }
      } else if (step === 4) {
        if (this.selectedWorkflowStage.Step >= 3) {
          this.goToWorkFlowPage('next_steps')
        }
      }
    }
  }
}
</script>
