<template>
  <div
    v-if="!userData.aut"
    class="m-auto"
    style="color:#ff7e00;padding:5px;overflow-x: hidden;"
    :style="{'transform': !mobileView ? 'translateY(-80px) scale(0.92)' : 'scale(0.92)'}"
  >
    <!--login button and strap line-->
    <img
      :src="userData.lbl.Logo"
      class="m-auto"
      alt="Shine Go"
      style="height:60px;object-fit:cover;display: block;margin-bottom:20px !important;"
      :class="{ 'margTop': mobileView }"
    >
    <div style="height:420px;transform: scale(0.96);background-color: #fff;background-clip: border-box;border: 1px solid rgba(0,0,0,.125);border-radius: .25rem;box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);">
      <sign-in
        v-show="loginFlag"
        @setPassword="loginFlag = false"
      />
      <forgotten-password
        v-show="!loginFlag"
        @setPassword="loginFlag = true"
      />
    </div>

    <!--footer-->
    <mdb-row style="transform: translateY(-10px)">
      <mdb-col style="font-size:x-small;margin-top:20px;color:#b1b1b1;">
        powered by <a
          href="http://www.shineinterview.com"
          target="_blank"
          style="color:#b1b1b1 !important;"
        >www.shineinterview.com</a> &copy;{{ new Date().getFullYear() }} {{ VERSION }}
      </mdb-col>
    </mdb-row>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import { setDefaultLanding } from '@/utils/defaultLanding.js'
import { VERSION } from '@/common/config.js'
import signIn from '../components/signIn'
import forgottenPassword from '../components/forgottenPassword'

export default {
  name: 'Home',
  components: {
    signIn,
    forgottenPassword
  },
  setup () {
    const userStore = useUserData()
    const { authData, userData, landingPage } = storeToRefs(userStore)
    const { mobileView } = useMobile()

    return {
      authData,
      userData,
      landingPage,
      mobileView
    }
  },
  data: () => {
    return {
      windowHeight: window.innerHeight,
      loginFlag: true,
      windowWidth: window.innerWidth
    }
  },
  computed: {
    VERSION () { return VERSION }
  },
  async created () {
    if (this.userData.aut) await this.defaultRoute()
    this.windowHeight = window.innerHeight
  },
  methods: {
    async defaultRoute () {
      try {
        await setDefaultLanding(this.userData.dlp)
        this.$router.push(this.landingPage)
      }
      catch {
        this.$router.push('/')
      }
    },
    register () {
      this.$router.push('/signup').catch(() => { })
    }
  }
}
</script>
